const isString = (value: any): boolean => typeof value === 'string';

const isBoolean = (value: any): boolean => typeof value === 'boolean';

const isDefined = (value: any): boolean =>
  value !== undefined && value !== null;

const isNotDefined = (value: any): boolean =>
  value === undefined || value === null;

const isFunction = (value: any): boolean => typeof value === 'function';

const isNumber = (value: any): boolean => typeof value === 'number';

const isObject = (value: any): boolean => typeof value === 'object';

const isArray = (value: any): boolean => Array.isArray(value);

const isEmptyString = (value: any): boolean =>
  typeof value !== 'string' || value.length === 0;

const isEmpty = (value: any): boolean => {
  if (isNotDefined(value)) {
    return true;
  }
  switch (typeof value) {
    case 'string': {
      value = String(value);
      return isEmptyString(value);
    }
    case 'number':
      return false;
    default:
      return Object.keys(value).length === 0;
  }
};

const formatErrors = (response: any): string[] => {
  const messages = [];
  // TODO: fix: questo funziona se gli errori sono dentro response.message
  // l'errore principale dovrebbe stare lì,
  // invece i vari errori dovrebbero stare su results
  if (isDefined(response.message)) {
    if (isString(response.message)) {
      messages.push(response.message);
    } else if (isArray(response.message)) {
      for (let i = 0; i < response.message.length; i++) {
        const data = response.message[i];
        if (isString(data)) {
          messages.push(data);
        } else if (isDefined(data.constraints)) {
          const keys = Object.keys(data.constraints);
          for (let j = 0; j < keys.length; j++) {
            messages.push(data.constraints[keys[j]]);
          }
        }
      }
    }
  }

  return messages;
};

type EnumType = { [s: number]: string };
type SameKeyValue<T> = { [K in keyof T]: string };
function keyValueFromEnum<T>(enumerator: EnumType): SameKeyValue<T> {
  for (const key in enumerator) {
    if (enumerator.hasOwnProperty(key)) {
      enumerator[key] = key;
    }
  }
  return enumerator as unknown as SameKeyValue<T>;
}

const replaceAll = (str: string, find: string, replace: string): string => {
  return str.replace(new RegExp(find, 'g'), replace);
};

export const Utils = {
  isString,
  isBoolean,
  isDefined,
  isNotDefined,
  isFunction,
  isNumber,
  isObject,
  isArray,
  isEmptyString,
  isEmpty,
  formatErrors,
  keyValueFromEnum,
  replaceAll,
};
