import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import sdk from '../../../app-lib/sdk/sdk.service';
import Loader from '../components/atoms/loader';
import List, { ListArgs } from '../components/organisms/list.component';
import { UpdateForm } from '../components/organisms/updateForm.component';

export default function Menu(props: any): JSX.Element {
  const [pageLoaded, setPageLoaded] = useState(false);

  const [menu, setMenu] = useState<ResponseResults.Menu>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<false | string[]>(false);

  // const [sections, setSections] = useState<ResponseResults.MenuSection[]>();

  // TODO: creare custom hook
  const menuId = Number(useParams<any>().menuId);
  const history = useHistory();

  const loadData = useCallback(() => {
    sdk.getMenu(menuId)
      .then(res => {
        setMenu(res.results);
        console.log(res.results)
        // sdk.getse
      })
      .catch(res => setErrors(res))
      .finally(() => {
        setPageLoaded(true)
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData()
  }, []);

  const ListSection = List as React.FC<ListArgs<ResponseResults.MenuSection>>;

  return (
    <div className="container">
      <h1 className="text-center">Menù</h1>

      {!pageLoaded || loading || !menu
        ? (
          <div className="text-center">
            <Loader />
          </div>
        )
        : (
          <div className="col-6 offset-3">
            <UpdateForm
              model={menu}
              {...loading}
              fields={[
                {
                  name: 'name',
                  type: 'text',
                  placeholder: 'Enter Menu name...',
                },
                {
                  name: 'is_active',
                  type: 'switch',
                  placeholder: 'Is active',
                },
                {
                  name: 'description',
                  type: 'text',
                  placeholder: 'Enter Menu description...',
                },
                {
                  name: 'image',
                  type: 'image',
                  placeholder: 'Add image...',
                },
              ]}
              onSubmit={(values: RequestFilters.MenuUpdate) => {
                setLoading(true);
                console.log('values',values);
                sdk.updateMenus(values)
                  .then(loadData)
                  .catch(e => {setErrors(e); setLoading(false)})
              }}
            />

            <ListSection
              model={menu.sections || []}
              title="Menu sections"
              addPlaceholder="Add section"
              generateListLink={(model: any) => {
                return `/menus/${menuId}/section/${model.id}`;
              }}
              onCreateNew={(value: string) => {
                sdk.createMenuSection({
                  name: value,
                  is_active: true,
                  menu_id: menu.id
                })
                  .then((section => history.push('/menus/' + menuId + '/section/' + section.results.id)))
                  .catch(e => setErrors(e))
              }}
            />

            {/* {errors &&
              errors.map(
                (el: string, key: number): JSX.Element => (
                  <p className="text-danger mt-2 mb-0" key={key}>
                    {el}
                  </p>
                ),
              )
            } */}
          </div>
        )
      }
    </div>
  );
}
