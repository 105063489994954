import { ActionInterface } from '../../../../lib/redux/models/action';
import { ResponseResults } from '../../../../app-lib/sdk/interfaces/ResponseResults';
import { USER } from './types';

export interface UserState {
  // user: false | UserInterface;
  user: false | any;
  loading: boolean;
  errors: false | string[];
}

const initialState: UserState = {
  loading: false,
  user: false,
  errors: false,
};

export default function userReducer(
  state: UserState = initialState,
  action: ActionInterface<ResponseResults.User & string[]>,
): UserState {
  switch (action.type) {
    case USER.USER_ME_REQUEST:
      return {
        ...state,
        loading: true,
        user: false,
        errors: false,
      };
    case USER.USER_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data,
      };
    case USER.USER_ME_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.data,
      };
    default:
      return state;
  }
}
