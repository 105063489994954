import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { loginRequest } from '../../../app/resources/auth/reducer/actions';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { AppState } from './../../store/reducers/index';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import '../scss/index.scss';

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Too Short!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  rememberMe: Yup.bool(),
});

const Error = (error: any): JSX.Element => (
  <span className="text-danger">{error}</span>
);

export default function Login(props: any): JSX.Element {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(logout());
  // }, [dispatch]);

  const auth = useSelector((state: AppState) => state.auth);
  if (auth.isAuthenticated) {
    return <Redirect to="/" from="" />;
  }

  return (
    <div className="bg-gradient-primary vh-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <Formik
                        initialValues={{
                          email: '',
                          password: '',
                          remember: false,
                        } as RequestFilters.Credentials}
                        validationSchema={LoginSchema}
                        onSubmit={(values: RequestFilters.Credentials): void => {
                          dispatch(loginRequest(values));
                        }}>
                        {(): JSX.Element => (
                          <Form>
                            <div className="user">

                              <div className="form-group">
                                <Field
                                  type="email"
                                  name="email"
                                  className="form-control form-control-user"
                                  placeholder="Enter Email Address..."
                                  aria-describedby="email"
                                  id="email"
                                />
                                <ErrorMessage name="email" render={Error} />
                              </div>
                              <div className="form-group">
                                <Field
                                  type="password"
                                  name="password"
                                  className="form-control form-control-user"
                                  placeholder="Password"
                                  id="password"
                                />
                                <ErrorMessage name="password" render={Error} />
                              </div>
                              {/* <div className="form-group">
                                <div className="custom-control custom-checkbox small">
                                  <Field
                                    type="checkbox"
                                    name="rememberMe"
                                    className="custom-control-input"
                                    id="rememberMe"
                                  />
                                  <label className="custom-control-label" htmlFor="rememberMe">Remember Me</label>
                                </div>
                              </div> */}
                              <button
                                className="btn btn-primary btn-user btn-block"
                                type="submit"
                                disabled={auth.loading}>
                                Login
                              </button>

                              {/* <hr />
                              <a href="index.html" className="btn btn-google btn-user btn-block">
                                <i className="fab fa-google fa-fw"></i> Login with Google
                              </a>
                              <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                              </a> */}


                            </div>

                            {auth.errors &&
                              auth.errors.map(
                                (el: string, key: number): JSX.Element => (
                                  <p className="text-danger mt-2 mb-0" key={key}>
                                    {el}
                                  </p>
                                ),
                              )}
                          </Form>
                        )}
                      </Formik>
                      {/* <hr /> */}
                      {/* <div className="text-center">
                        <a className="small" href="forgot-password.html">Forgot Password?</a>
                      </div>
                      <div className="text-center">
                        <a className="small" href="register.html">Create an Account!</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
