import React, { useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { RequestFilters } from '../../../../app-lib/sdk/interfaces/RequestFilters';
import imageDefault from '../../assets/img/placeholder.jpg';

const InputFile = ({name, initialValue, label, onFileChange}: any) => {
  const [thumb, setThumb] = useState(initialValue || imageDefault);
  const thumbRef = useRef(null)

  return (
    <>
      <label
        htmlFor="file"
        style={{
          height: '300px',
          marginBottom: 0,
          display: 'block',
          objectFit: 'contain',
          backgroundImage: 'url(' + thumb + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderTopLeftRadius: '0.35rem',
          borderTopRightRadius: '0.35rem',
        }}
        ref={thumbRef}
      />
      <input
        id="file"
        name={name}
        type="file"
        accept="image/*"
        onChange={async (event) => {
          if (event.currentTarget) {
            const { files } = event.currentTarget;
            const val = files && files.length > 0 ? files[0] : undefined;
            setThumb(val);
            onFileChange(val);

            var reader = new FileReader();
            reader.onload = function (e) {
              (thumbRef.current as any).style.backgroundImage = 'url(' + (e.target as any).result + ')';
            };
            reader.readAsDataURL(val === undefined ? (await fetch(imageDefault).then(r => r.blob())) : val);
          }
        }}
        className="form-control"
        style={{
          width: '100%',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      />
    </>
  )
}

export const BaseForm = ({values, fields, setFieldValue}: any) => (
  <>
    {fields.map((field: any, idx: number) => (
      <div key={'ae'+idx} className="form-group">
        {(() => {
          switch (field.type) {
            case 'text':
              return (
                <>
                  <InputText
                    name={field.name}
                    placeholder={field.placeholder}
                    initialValue={values[field.name]}
                    onChange={(value: any) => setFieldValue(field.name, value)}
                  />
                  <ErrorMessage name={field.name} render={Error} />
                </>
              )
            case 'number':
              return (
                <>
                  <InputNumber
                    name={field.name}
                    placeholder={field.placeholder}
                    initialValue={values[field.name]}
                    onChange={(value: any) => setFieldValue(field.name, value)}
                  />
                  <ErrorMessage name={field.name} render={Error} />
                </>
              )
            case 'price':
              return (
                <>
                  <Field
                    type="number"
                    name={field.name}
                    className="form-control"
                    min="0"
                    step=".01"
                    placeholder={field.placeholder}
                    aria-describedby={field.name}
                  />
                  <ErrorMessage name={field.name} render={Error} />
                </>
              )
            case 'image':
              return (
                <InputFile
                  name={field.name}
                  initialValue={values[field.name]}
                  // label={'Add file'}
                  onFileChange={(file: any) => setFieldValue(field.name, file)}
                />
              )
            case 'checkbox':
            case 'switch':
              return (
                <InputCheckbox
                  name={field.name}
                  placeholder={field.placeholder}
                  initialValue={values[field.name]}
                  onChange={(value: any) => setFieldValue(field.name, value)}
                />
              )
            default:
              return (
                <p>Type not recognized</p>
              )
          }
        })()}
      </div>
    ))}
  </>
)

const InputCheckbox = ({initialValue, name, placeholder, onChange}: any) => {
  const id = 'checkbox-' + Math.round(Math.random()*10000);
  const [status, setStatus] = useState(initialValue === undefined ? true : !!initialValue);

  return (
    <>
      <div className="form-group">
        <div className="custom-control custom-checkbox small">
          <Field
            type="checkbox"
            name={name}
            className="custom-control-input"
            id={id}
            checked={status}
            onChange={() => {
              onChange(!status);
              setStatus(!status)
            }}
          />
          <label className="custom-control-label" htmlFor={id}>{placeholder}</label>
        </div>
      </div>
    </>
  )
}

const InputNumber = ({name, placeholder, initialValue, onChange}: any) => {
  const [value, setValue] = useState(initialValue || '');

  return (
    <Field
      type="number"
      className="form-control"
      aria-describedby={name}
      placeholder={placeholder}
      value={value}
      onChange={({target}: any) => {
        onChange(target.value)
        setValue(target.value)
      }}
    />
  )
}

const InputText = ({name, placeholder, initialValue, onChange}: any) => {
  const [value, setValue] = useState(initialValue || '');

  return (
    <Field
      type="text"
      className="form-control"
      aria-describedby={name}
      placeholder={placeholder}
      value={value}
      onChange={({target}: any) => {
        onChange(target.value)
        setValue(target.value)
      }}
    />
  )
}

// export const FormStruct = (props: any) => {
//   return <></>;
// }
// export const FormArray = (props: any) => {
//   return <></>;
// }
// export const FormInput = (props: any) => {
//   return <></>;
// }

// const IModelCheck = (initialValue: boolean, name: string, groupName?: string) => {}
// const IModelDate = () => {}

// <FormStruct
//   title="Pizze"
//   description=""
// >
//   <FormInput
//     input={IModelCheck(true, 'enabled')}
//     id=""
//     type="default"
//   />

//   <FormArray
//     title=""
//   >
//     <FormInput
//       input={[IModelDate(), IModelDate()]}
//     />
//   </FormArray>

//   <FormArray
//     title="Disponibilità settimanale"
//   >
//     <FormInput
//       input={[IModelCheck(true, 'lun','day'), IModelCheck(true, 'mar','day')]}
//     />
//   </FormArray>

//   <FormArray
//     title="Disponibilità settimanale"
//   >
//     <FormInput
//       input={[IModelCheck(true, 'lun','day'), IModelCheck(true, 'mar','day')]}
//     />
//   </FormArray>

// </FormStruct>

export const UpdateForm: React.FC<any> = ({model, loading, onSubmit, fields}) => (
  <Formik
    initialValues={(() => {
      return Object.fromEntries(Object.entries(model).map(([k,v]) => [k, k.startsWith('price') && v ? v as number / 100 : 0 || v || ''])) as unknown as RequestFilters.MenuUpdate
    })()}
    // validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {props => (
      <Form>
        <BaseForm values={props.values} fields={fields} setFieldValue={(props.setFieldValue)} />
        <button
          className="btn btn-primary btn-block"
          type="submit"
          disabled={loading}
        >
          Edit
        </button>
      </Form>
    )}
  </Formik>
);
