import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';

import { ActionInterface } from './../../lib/redux/models/action';
import { ConfigureStoreType } from './../../lib/redux/store/configureStore';
import { configureStore } from '../../lib/redux/store/configureStore';

import rootReducer, { AppState } from './reducers';
import rootSaga from '../saga';
import { GenericObject } from '../../lib/utils/models';

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  // whitelist: ['auth', 'user'],
  blacklist: ['auth', 'user'],
};

export type Action = ActionInterface<GenericObject<any>>;

const { store, persistor }: ConfigureStoreType<Action> = configureStore<
  AppState,
  Action
>(rootReducer, rootSaga, persistConfig);

export const getStore = () => {
  return { store, persistor };
}
