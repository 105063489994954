import { ActionInterface } from '../../../../lib/redux/models/action';
import { RequestFilters } from '../../../../app-lib/sdk/interfaces/RequestFilters';
import { ResponseResults } from '../../../../app-lib/sdk/interfaces/ResponseResults';
import { AUTH } from './types';

export function loginRequest(
  data: RequestFilters.Credentials,
): ActionInterface<RequestFilters.Credentials> {
  return {
    type: AUTH.LOGIN_REQUEST,
    data,
  };
}

export function loginSuccess(
  data: ResponseResults.AuthLogin,
): ActionInterface<ResponseResults.AuthLogin> {
  return {
    type: AUTH.LOGIN_SUCCESS,
    data,
  };
}

export function loginFailure(
  errors: string[],
): ActionInterface<string[]> {
  return {
    type: AUTH.LOGIN_FAILURE,
    data: errors,
  };
}

export function logoutRequest(): ActionInterface<undefined> {
  return {
    type: AUTH.LOGOUT_REQUEST,
    data: undefined,
  };
}

export function logoutSuccess(): ActionInterface<undefined> {
  return {
    type: AUTH.LOGOUT_SUCCESS,
    data: undefined,
  };
}

export function logoutFailure(
  errors: string[],
): ActionInterface<string[]> {
  return {
    type: AUTH.LOGOUT_FAILURE,
    data: errors,
  };
}

