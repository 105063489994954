import { RequestFilters } from '../../../../app-lib/sdk/interfaces/RequestFilters';
import { ResponseResults } from '../../../../app-lib/sdk/interfaces/ResponseResults';
import { ActionInterface } from '../../../../lib/redux/models/action';
import { GenericObject } from '../../../../lib/utils/models';

import { MENU } from './types';

export function menuGetAllRequest(): ActionInterface<GenericObject<any>> {
  return {
    type: MENU.MENU_GETALL_REQUEST,
    data: {},
  };
}

export function menuGetAllSuccess(
  data: ResponseResults.Menu[],
): ActionInterface<ResponseResults.Menu[]> {
  return {
    type: MENU.MENU_GETALL_SUCCESS,
    data,
  };
}

export function menuGetAllFailure(
  errors: GenericObject<any>,
): ActionInterface<GenericObject<any>> {
  return {
    type: MENU.MENU_GETALL_FAILURE,
    data: errors,
  };
}

export function menuGetRequest(
  data: {
    id: number
  },
  // filters?: any
): ActionInterface<GenericObject<any>> {
  return {
    type: MENU.MENU_GET_REQUEST,
    data,
  };
}

export function menuGetSuccess(
  data: ResponseResults.Menu,
): ActionInterface<ResponseResults.Menu> {
  return {
    type: MENU.MENU_GET_SUCCESS,
    data,
  };
}

export function menuGetFailure(
  errors: GenericObject<any>,
): ActionInterface<GenericObject<any>> {
  return {
    type: MENU.MENU_GET_FAILURE,
    data: errors,
  };
}

export function menuCreateRequest(data: RequestFilters.MenuCreate): ActionInterface<RequestFilters.MenuCreate> {
  return {
    type: MENU.MENU_CREATE_REQUEST,
    data,
  };
}

export function menuCreateSuccess(
//   data: MenuInterface,
// ): ActionInterface<MenuInterface> {
  data: any,
): ActionInterface<any> {
  return {
    type: MENU.MENU_CREATE_SUCCESS,
    data,
  };
}

export function menuCreateFailure(
  errors: GenericObject<any>,
): ActionInterface<GenericObject<any>> {
  return {
    type: MENU.MENU_CREATE_FAILURE,
    data: errors,
  };
}

export function menuUpdateRequest(data: RequestFilters.MenuUpdate): ActionInterface<RequestFilters.MenuUpdate> {
  return {
    type: MENU.MENU_UPDATE_REQUEST,
    data,
  };
}

export function menuUpdateSuccess(
//   data: MenuInterface,
// ): ActionInterface<MenuInterface> {
  data: any,
): ActionInterface<any> {
  return {
    type: MENU.MENU_UPDATE_SUCCESS,
    data,
  };
}

export function menuUpdateFailure(
  errors: GenericObject<any>,
): ActionInterface<GenericObject<any>> {
  return {
    type: MENU.MENU_UPDATE_FAILURE,
    data: errors,
  };
}

export function menuAddSectionRequest(
  data: any
  // RequestFilters.MenuCreate
// ): ActionInterface<RequestFilters.MenuCreate> {
): ActionInterface<any> {
  return {
    type: MENU.MENU_ADDSECTION_REQUEST,
    data,
  };
}

export function menuAddSectionSuccess(
  data: any,
): ActionInterface<any> {
  return {
    type: MENU.MENU_ADDSECTION_SUCCESS,
    data,
  };
}

export function menuAddSectionFailure(
  errors: GenericObject<any>,
): ActionInterface<GenericObject<any>> {
  return {
    type: MENU.MENU_ADDSECTION_FAILURE,
    data: errors,
  };
}
