import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import { useHistory, useParams } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import List, { ListArgs } from '../components/organisms/list.component';
import { UpdateForm } from '../components/organisms/updateForm.component';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import Autocomplete from '../components/atoms/autocomplete';
import { Modal } from '../components/molecules/modal';

export default function Dish(props: any): JSX.Element {
  const history = useHistory();

  const params = useParams<{menuId: string, sectionId: string, dishId: string}>()

  const [loading, setLoading] = useState(true);
  const [dish, setDish] = useState<ResponseResults.Dish>();
  const [ingredients, setIngredients] = useState<ResponseResults.Ingredient[]>([]);
  const [filteredIngredients, setFilteredIngredients] = useState<ResponseResults.Ingredient[]>([]);
  const [tags, setTags] = useState<ResponseResults.Tag[]>([]);
  const [filteredTags, setFilteredTags] = useState<ResponseResults.Tag[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<false | string>(false);

  const loadData = useCallback(() => {
    setLoading(true);
    sdk.getMenuSectionDish(Number(params.dishId))
      .then(res => {
        console.log('setto', res.results);
        setDish(res.results)
        sdk.getIngredients()
          .then(res => setIngredients(res.results))
          .catch(res => setErrors(res))
          .finally(() => setLoading(false))
        sdk.getTags()
          .then(res => setTags(res.results))
          .catch(res => setErrors(res))
          .finally(() => setLoading(false))
      })
      .catch(res => setErrors(res))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadData()
  }, []);

  // const ListIngredient = List as React.FC<ListArgs<ResponseResults.Ingredient>>;

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error) => (
            <p>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Piatto</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-6 offset-3">
            <UpdateForm
              model={dish}
              {...loading}
              fields={[
                // {
                //   name: 'is_active',
                //   type: 'switch',
                //   placeholder: 'Is active',
                // },
                {
                  name: 'name',
                  type: 'text',
                  placeholder: 'Enter dish name...',
                },
                {
                  name: 'price_default',
                  type: 'price',
                  placeholder: 'Enter dish price...',
                },
                {
                  name: 'description',
                  type: 'text',
                  placeholder: 'Enter dish description...',
                },
                // {
                //   name: 'image',
                //   type: 'image',
                //   placeholder: 'Add image...',
                // },
              ]}
              onSubmit={(values: RequestFilters.DishUpdate) => {
                setLoading(true);
                sdk.updateDish({...values, price_default: values.price_default * 100})
                  .then(loadData)
                  .catch(e => {setErrors(e); setLoading(false)})
              }}
            />

            <div className="mt-3">
              <p className="paragraph mt-3 mb-0 text-dark text-uppercase font-weight-bold">
                Tags
              </p>
              <p>
                {dish?.tags?.map(i => i.name).join(', ')}
              </p>
              <Autocomplete
                suggestions={filteredTags.map(fi => fi.name)}
                onTypeComplete={(query: string) => {
                  setFilteredTags(
                    tags
                      // @ts-ignore
                      .filter(tag => dish.tags.filter(i => i.name === tag.name).length === 0)
                      .filter(tag => tag.name.toLowerCase().includes(query.toLowerCase()))
                  )
                }}
                onNewValue={(value) => {
                  // TODO: aprire modal con richiesta dati
                  if (tags.filter(tag => tag.name === value).length === 0) {
                    sdk.createTag({
                      name: value,
                      dish_id: dish?.id,
                    }).then(loadData)
                  }
                }}
                onSuggestionSelect={(value) => {
                  sdk.updateDishAddTag({
                    dish_id: dish?.id as any,
                    tag_id: tags.find(i => i.name === value)?.id as unknown as string,
                  }).then(loadData)
                }}
              />
            </div>

            <div className="mt-3">
              <p className="paragraph mt-3 mb-0 text-dark text-uppercase font-weight-bold">
                Ingredients
              </p>
              <p>
                {dish?.ingredients?.map(i => i.name).join(', ')}
              </p>
              <Autocomplete
                suggestions={filteredIngredients.map(fi => fi.name)}
                onTypeComplete={(query: string) => {
                  setFilteredIngredients(
                    ingredients
                      // @ts-ignore
                      .filter(ingredient => dish.ingredients.filter(i => i.name === ingredient.name).length === 0)
                      .filter(ingredient => ingredient.name.toLowerCase().includes(query.toLowerCase()))
                  )
                }}
                onNewValue={(value) => {
                  // TODO: aprire modal con richiesta dati
                  if (ingredients.filter(ingr => ingr.name === value).length === 0) {
                    setOpenModal(value);
                  }
                }}
                onSuggestionSelect={(value) => {
                  sdk.updateDishAddIngredient({
                    dish_id: dish?.id as any,
                    ingredient_id: ingredients.find(i => i.name === value)?.id as unknown as string,
                  }).then(loadData)
                }}
              />
            </div>

            {openModal && (
              <Modal
                onClose={() => setOpenModal(false)}
              >
                <UpdateForm
                  model={{
                    name: openModal,
                  }}
                  {...loading}
                  fields={[
                    {
                      name: 'name',
                      type: 'text',
                      placeholder: 'Enter menu section name...',
                    },
                    {
                      name: 'vegan',
                      type: 'switch',
                      placeholder: 'vegan',
                    },
                    {
                      name: 'bio',
                      type: 'switch',
                      placeholder: 'bio',
                    },
                    {
                      name: 'frozen',
                      type: 'switch',
                      placeholder: 'frozen',
                    },
                    {
                      name: 'vegetarian',
                      type: 'switch',
                      placeholder: 'vegetarian',
                    },
                  ]}
                  onSubmit={(values: RequestFilters.IngredientCreate) => {
                    setLoading(true);
                    setOpenModal(false);
                    sdk.createIngredient({
                      ...values,
                      // name: value,
                      price_default: 0,
                      price_sale: 0,
                      //. vegan: false,
                      notes: '',
                      //. bio: false,
                      //. frozen: false,
                      //. vegetarian: false,
                      // @ts-ignore
                      dish_id: dish.id,
                    }).then(loadData)
                  }}
                />
              </Modal>
            )}

            {/* <ListIngredient
              model={dish?.ingredients || []}
              title="Ingredients"
              addPlaceholder="Add ingredient"
              generateListLink={(model: any) => {
                return `/menus/${params.menuId}/section/${params.sectionId}/dish/${model.id}`;
              }}
              onCreateNew={(value: string) => {
                sdk.createIngredient({
                  name: value,
                  price: 0,
                  // @ts-ignore
                  // menu_sections: sections?.id
                })
                  .then((res: any) => console.log(res))
                  .catch((e: any) => setErrors(e))
              }}
            /> */}
          </div>
        )
      }
    </div>
  );
}
