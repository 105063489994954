import { ActionInterface } from './../models/action';
import { applyMiddleware, compose, createStore, Reducer, Store } from 'redux';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import { Persistor, PersistConfig } from 'redux-persist/es/types';

import logger from './logger';

export type StoreType<Action extends ActionInterface<any>> = Store<any, Action>

export type ConfigureStoreType<Action extends ActionInterface<any>> = {
  store: StoreType<Action>;
  persistor: Persistor;
}

export function configureStore<State, Action extends ActionInterface<any>>(
  rootReducer: Reducer<State, Action>,
  rootSaga: Saga,
  persistConfig: PersistConfig<any>
): ConfigureStoreType<Action> {
  const sagaMiddleware = createSagaMiddleware(/*{
    onError: (e) => {
      console.log('saga middleware error', e)
      // how to access dispatch at this point?
    }
  }*/);

  const middleware = [sagaMiddleware, logger];
  const enhancers = [];

  enhancers.push(applyMiddleware(...middleware));

  // Redux persist
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore<any, Action, any, any>(persistedReducer, compose(...enhancers));
  const persistor = persistStore(store);

  // Kick off the root saga
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
