import React, { useEffect, useState } from 'react';

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
// import appRoutes from "./appRoutes"

import Analytics from './theme/views/analytics';

import Menus from './theme/views/menus';
import Menu from './theme/views/menu';
import MenuSection from './theme/views/menu-section';
import Dish from './theme/views/dish';
import NotFound from './theme/views/not-found';
import AppTemplate from './theme/components/templates/app.template';
import Login from './theme/views/login';

import { Provider, useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { getStore } from './store';
import { AppState } from './store/reducers';
import { startupSuccess } from './store/reducers/general/actions';

const { store, persistor } = getStore();

// import routerService from '../../services/router.service';
// import appRoutes from '../../appRoutes';
// import { IRoute } from '../../models/route';
// import NotFound from '../../views/not-found';

// // type RouteProps = { component: React.FC; subRoutes?: any } & RouteComponentProps;
// // const Route: React.FC<RouteProps> = ({ component: Component, subRoutes, ...rest }) => {
// //   console.log(subRoutes)
// //   return (
// //     <Component {...rest}>
// //       {subRoutes && subRoutes.map((child: any) => {
// //         console.log(child)
// //         return <Route {...child} />
// //       })}
// //     </Component>
// //   )
// // };

// function App() {
//   const routes = routerService.loadRoutes(appRoutes);

//   const tmp = ({subRoutes, component: Component, ...route}: IRoute<React.FC>, idx: number) => {
//     return (
//       <Component key={route.path} {...route}>
//         {subRoutes && subRoutes.map(tmp)}
//       </Component>
//     );
//   };

//   // const tmp = ([path, {subRoutes, component: Component, ...route}]: [string, IRoute<React.FC>]) => {
//   //   return (
//   //     <Component {...route}>
//   //       {/* {subRoutes && subRoutes.map(tmp)} */}
//   //     </Component>
//   //   );
//   // };

//   return (
//     <Router>
//       <NotFound key="def" default />

//       {/* <NotFound default /> */}
//       {/* {true && (function() {
//         return <NotFound default />
//       })()} */}
//       {routes.map(tmp)}
//     </Router>
//   );
// }

// export default App;

function PrivateRoute(props: any) {
  const auth = useSelector((state: AppState) => state.auth);
  // const user = useSelector((state: AppState) => state.user.user);

  if (auth.isAuthenticated) {
    // if (role && user && role !== user.role) {
    //   return <Redirect from="" to="/" />;
    // }
    return <Route {...props} />;
  }

  return <Redirect from="" to="/login" />;
}

const Wrapper: React.FC = () => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    dispatch(startupSuccess());
    setReady(true);
  }, [dispatch]);

  return (
    <>
      {ready ? (
        <BrowserRouter>
          <Switch>
            {/* TODO: impedire l'accesso all'utente loggato */}
            <Route exact path={'/login'}>
              <Login />
            </Route>

            <AppTemplate>
              <Switch>
                {/* <PrivateRoute exact path={'/'}>
                  <Analytics />
                </PrivateRoute> */}
                {/* <PrivateRoute exact path={'/home'}>
                  <Analytics />
                </PrivateRoute> */}
                <Redirect from="/" to="/menus" exact />
                <PrivateRoute exact path={'/menus'}>
                  <Menus />
                </PrivateRoute>
                {/* <PrivateRoute exact path={'/menus/create'}>
                  <MenusCreate />
                </PrivateRoute> */}
                <PrivateRoute exact path={'/menus/:menuId'}>
                  <Menu />
                </PrivateRoute>
                {/* <PrivateRoute exact path={'/menus/:menuId/section'}>
                  <Redirect from="/section" to="/menus/:menuId" />
                </PrivateRoute> */}

                <PrivateRoute exact path={'/menus/:menuId/section/:sectionId'}>
                  <MenuSection />
                </PrivateRoute>

                <PrivateRoute exact path={'/menus/:menuId/section/:sectionId/dish/:dishId'}>
                  <Dish />
                </PrivateRoute>

                <Redirect from="/menus/:menuId/section" to="/menus/:menuId" />
                <Redirect from="/menus/:menuId/section/:sectionId/dish" to="/menus/:menuId/section/:sectionId" />

                <PrivateRoute path="*">
                  <NotFound />
                </PrivateRoute>
              </Switch>
            </AppTemplate>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </BrowserRouter>
      ) : <p>loading</p>}
    </>
  );
};

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Wrapper />
    </PersistGate>
  </Provider>
)

export default App;
