
import React, { useEffect, useState } from 'react';
// import Link from 'next/link';

// import React, { useState } from 'react';

// import './style.scss';
// import Link from 'next/link';

// // const boldQuery = (str, query) => {
// //   if (query.length > str.length)
// //     return str;

// //   let newStr = '';

// //   for (let i = 0, j = 0; i < str.length; ++i) {
// //     if (str[i] === query[j]) {
// //       newStr += str[i].bold();
// //       ++j;
// //     } else {
// //       newStr += str[i];
// //     }
// //   }

// //   if (j < query.length) {
// //     return str
// //   }

// //   return newStr.replace('</b><b>', '');
// // }
const boldQuery = (str: string, query: string) => {
  const n = str.toUpperCase();
  const q = query.toUpperCase();
  const x = n.indexOf(q);
  if (!q || x === -1) {
      return str; // bail early
  }
  const l = q.length;
  return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
}

// interface AutocompleteProps<T extends any[] = any[]> {
//   placeholder: string,
//   dataset: T,
//   searchFilter: (value: any, index: number, array: T) => T,
//   searchMap: (value: any, index: number, array: T) => {
//     slug: string,
//     title: string,
//   },
// }

// const Autocomplete: React.FC<AutocompleteProps> = ({ placeholder, dataset, searchFilter, searchMap }) => {
//   const [input, setInput] = useState('');

// 	return (
//     <div className="autocomplete">
//       <input
//         className="form-control"
//         type="search"
//         placeholder={placeholder}
//         aria-label={placeholder}
//         value={input}
//         onChange={(e) => setInput(e.target.value)}
//       />
//       {input.length > 1 && (
//         <div className="autocomplete-items list-group">
//           {dataset.
//             filter(searchFilter).
//             map(searchMap).
//             map(({slug, title}) => (
//               <Link
//                 key={slug}
//                 href={slug}
//               >
//                 <a
//                   className="list-group-item list-group-item-action"
//                   dangerouslySetInnerHTML={{ __html: boldQuery(title, input)}}
//                 />
//               </Link>
//             ))}
//         </div>
//       )}
//     </div>
// 	)
// }

// export default Autocomplete;

interface AutocompleteProps/*<T extends any[] = any[]>*/ {
  // model: State,
  suggestions: string[],
  onSuggestionSelect: (value: string) => void,
  onTypeComplete: (data: any) => void,
  onNewValue: (value: string) => void,
}

// type State<T extends any = any> = [T, React.Dispatch<React.SetStateAction<T>>]

const Autocomplete: React.FC<AutocompleteProps> = ({
  suggestions,
  onSuggestionSelect,
  onTypeComplete,
  onNewValue,
}) => {
  const minLength: number = 1;
  // const delay: number = 300;

  const [input, setInput] = useState('');
  // const [loading, setLoading] = useState(false);

  // OnInput OnKeyDown OnKeyUp OnInputPaste
  const handleChange = (value: string) => {
    // if ()
    onTypeComplete(value);
  }

  // useEffect(() => {
  //   const handleDocumentKeyPress = (event: React.MouseEvent) => {
  //     console.log('keydown')
  //   };
  //   document.addEventListener('keydown', handleDocumentKeyPress as any);
  //   return () => {
  //     document.removeEventListener('keydown', handleDocumentKeyPress as any);
  //   };
  // }, []);

  return (
    <div className="autocomplete"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          const suggs = suggestions.filter(s => s === input);
          if (suggs.length > 0) {
            onSuggestionSelect(input);
          } else {
            onNewValue(input)
          }
        }
      }}
    >
      <input
        className="form-control"
        style={input.length > minLength ? {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: 'none',
          boxShadow: 'none',
        } : {
          borderColor: 'none',
          boxShadow: 'none',
        }}
        type="search"
        // placeholder={placeholder}
        // aria-label={placeholder}
        value={input}
        onChange={(e) => {
          setInput(e.target.value)
          handleChange(e.target.value);
        }}
      />
      {input.length > minLength && (
        <div className="autocomplete-items list-group" style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}>
          {!(suggestions.length > 0 && suggestions[0] === input) && (
            <span
              className="list-group-item list-group-item-action"
              dangerouslySetInnerHTML={{ __html: ' + ' + boldQuery(input, input)}}
              onClick={() => onNewValue(input)}
            />
          )}
          {suggestions
            .filter((s, i) => i <= 5)
            .map((label, idx) => (
              <span
                key={label+idx}
                className="list-group-item list-group-item-action"
                dangerouslySetInnerHTML={{ __html: boldQuery(label, input)}}
                onClick={() => onSuggestionSelect(label)}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default Autocomplete;
