// autogenerated file through 'yarn run reducer:import' command

import auth from './../../resources/auth/reducer/reducer';
import user from './../../resources/user/reducer/reducer';
import menu from './../../resources/menu/reducer/reducer';

export default {
	auth,
	user,
	menu,
}
