import { combineReducers } from 'redux';

import generalReducer from './general/reducer';
import reducers from './imports';

const rootReducer = combineReducers({
  /**
   * Register your reducers here.
   * @see https://redux.js.org/api-reference/combinereducers
   */
  general: generalReducer,
  ...reducers,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
