import { ActionInterface } from '../../../../lib/redux/models/action';
import { ResponseResults } from '../../../../app-lib/sdk/interfaces/ResponseResults';
import { AUTH } from './types';

export interface AuthState {
  token: boolean | string;
  loading: boolean;
  isAuthenticated: boolean;
  errors: false | string[];
}

const initialState: AuthState = {
  token: false,
  loading: false,
  isAuthenticated: false,
  errors: false,
};

export default function authReducer(
  state: AuthState = initialState,
  action: ActionInterface<ResponseResults.AuthLogin & string[]>,
): AuthState {
  switch (action.type) {
    case AUTH.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        token: false,
        isAuthenticated: false,
        errors: false,
      };
    case AUTH.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        token: action.data.access_token,
      };
    case AUTH.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.data,
      };
    case AUTH.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        errors: false,
      };
    case AUTH.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        token: false,
      };
    case AUTH.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.data,
      };
    default:
      return state;
  }
}
