import { AuthState } from './../resources/auth/reducer/reducer';
import { getAuth, automaticLogout } from './../resources/auth/auth.saga';
import { takeLatest, put, select } from '@redux-saga/core/effects';

import sdk from '../../app-lib/sdk/sdk.service';

import { GENERAL } from '../store/reducers/general/types';
import { userMeRequest } from '../resources/user/reducer/actions';

export function* startup(): Generator {
  const auth: AuthState = (yield select(getAuth)) as AuthState;
  if (auth.token) {
    sdk.setAuthorization(auth.token as string);
    yield put(userMeRequest());
    yield select(automaticLogout);
  }
  sdk.setLocale('it');
}

export const generalSaga = [
  takeLatest(GENERAL.STARTUP_SUCCESS, startup)
];
