import React from "react";
import { Link } from "react-router-dom";

import Logo from '../../assets/img/logo.svg';

export default (props: any) => (
  <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

    {/*  Sidebar - Brand --> */}
    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="">
      <div className="sidebar-brand-icon rotate-n-15">
        <img src={Logo} />
      </div>
      <div className="sidebar-brand-text mx-3">Al Pomodoro</div>
    </Link>

    {/*  Divider --> */}
    {/* <hr className="sidebar-divider my-0" />

    <li className="nav-item active">
      <Link className="nav-link" to="">
        <i className="fas fa-fw fa-home"></i>
        <span>Home</span>
      </Link>
    </li> */}

    {/*  Divider --> */}
    <hr className="sidebar-divider" />

    {/*  Heading --> */}
    <div className="sidebar-heading">
      Menu
    </div>

    <li className="nav-item">
      <Link className="nav-link" to="/menus">
        <i className="fas fa-fw fa-utensils"></i>
        <span>Menu</span>
      </Link>
    </li>

    {/* <li className="nav-item">
      <Link className="nav-link" to="">
        <i className="fas fa-fw fa-qrcode"></i>
        <span>Il mio QR Code</span>
      </Link>
    </li>

    <hr className="sidebar-divider" /> */}

    {/*  Heading --> */}
    {/* <div className="sidebar-heading">
      Ordini
    </div>

    <li className="nav-item">
      <Link className="nav-link" to="">
        <i className="fas fa-fw fa-book-open"></i>
        <span>Visualizza ordini</span>
      </Link>
    </li>

    <li className="nav-item">
      <Link className="nav-link" to="">
        <i className="fas fa-fw fa-pen-square"></i>
        <span>Nuovo ordine</span>
      </Link>
    </li>

    <hr className="sidebar-divider" /> */}

    {/*  Heading --> */}
    {/* <div className="sidebar-heading">
      Utilità
    </div>

    <li className="nav-item">
      <Link className="nav-link" to="">
        <i className="fas fa-fw fa-eye"></i>
        <span>Anteprima</span>
      </Link>
    </li>

    <li className="nav-item">
      <Link className="nav-link" to="">
        <i className="fas fa-fw fa-life-ring"></i>
        <span>Assistenza</span>
      </Link>
    </li> */}

    {/*  Divider --> */}
    <hr className="sidebar-divider d-none d-md-block" />

    {/*  Sidebar Toggler (Sidebar) --> */}
    {/* <div className="text-center d-none d-md-inline">
      <button className="rounded-circle border-0" id="sidebarToggle"></button>
    </div> */}

  </ul>
);
