// import { JWT } from './../../../lib/utils/jwt';
import { BaseSDK, ResponseSDKBase } from '../../../lib/sdk/core/BaseSDK';
import { Headers } from '../../../lib/sdk/interfaces/Headers';
import { RequestHandler } from '../../../lib/sdk/interfaces/RequestHandler';

import { ResponseResults } from '../interfaces/ResponseResults';
import { RequestFilters } from '../interfaces/RequestFilters';
import { Environment, Constants } from '../../constants';

export class AppSDK<R extends RequestHandler> extends BaseSDK<R> {
  protected baseUrl: string;
  protected multipartHeader = new Headers(
    [
      ['Content-Type', 'multipart/form-data']
    ]
  )

  constructor(
    protected builderRequest: new (defaultHeaders?: Headers) => R,
    protected env: Environment = 'production',
    // protected authorization: ResponseResults.Authorization,
  ) {
    super(builderRequest);
    this.baseUrl = Constants.API.BASEURL[this.env] || Constants.API.BASEURL.production;
  }

  protected buildMultipartRequestOptions({ file, data, method }: {
    file: [string, any],
    data: [string, any][],
    method: 'get' | 'post' | 'put' | 'delete'
  }) {
    const fd = new FormData();
    fd.append('_method', method);
    fd.append(file[0], file[1]);
    data.forEach(d => {
      console.log(d);
      if (Array.isArray(d[1])) {
        fd.append(d[0], JSON.stringify(d[1]));
      } else {
        fd.append(d[0],
          JSON.stringify(
            // remove void values
            Object.fromEntries(
              Object.entries(d[1])
                // TODO: rimuovere perché altrimenti se svuoto un campo non me lo salva
                .filter(
                  ([, value]) => value !== undefined && value !== null && value !== ''
                )
            )
          )
        );
      }
    })

    return {
      data: fd,
      headers: this.multipartHeader,
    }
  }

  // private buildHeaders(): Promise<boolean> {
  //   // this.headers['X-Request-ID'] = this.platform.getID();
  //   // this.defaultHeaders.set()
  //   return this.isTokenExpired().then(isTokenExpired => {
  //     if (!isTokenExpired && Utils.isDefined(this.token)) {
  //       this.defaultHeaders.set('Authorization', 'Bearer ' + this.token);
  //     }
  //     return true;
  //   });
  // }

  // private isTokenExpired(): Promise<boolean> {
  //   // console.log('is token expired');
  //   return this.storage
  //     .get('token')
  //     .then((token: string) => {
  //       if (!JWT.isTokenExpired(token)) {
  //         this.token = token;
  //         return false;
  //       } else {
  //         // console.log('chiamo il token expired');
  //         return this.refreshToken().then((newToken: string) => {
  //           // console.log('prova', newToken);
  //           if (Utils.isNotDefined(newToken)) {
  //             return true;
  //           }
  //           this.token = newToken;
  //           return false;
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       console.log('token not found');
  //       return true;
  //     }); // token not found
  // }

  /* TODO: AUTH - spostare in lib (e aggiungere con mixin) */
  // public async authenticate(apiKey: string, apiSecret: string): Promise<boolean>;
  public async authenticate(credentials: RequestFilters.Credentials): Promise<ResponseSDKBase<ResponseResults.AuthLogin>> {
    // const bearerResult: ResponseResults.Authorization = await this.getBearer(credentials);
    // this.setAuthorization(bearerResult.bearer);
    // return true;
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.AuthLogin>>(this.baseUrl + 'auth/login', {
      data: {
        ...credentials,
      }
    });
    return response;
  }

  // public async getBearer({ email, password }: RequestFilters.Credentials): Promise<ResponseSDKBase<ResponseResults.Authorization>> {
  //   const request: R = new this.builderRequest(this.defaultHeaders);
  //   const response = await request.post<ResponseSDKBase<ResponseResults.AuthLogin>>(this.baseUrl + 'auth/login', {
  //   // const response = await request.post<ResponseResults.AuthLogin>(this.baseUrl + 'auth/login', {
  //     data: {
  //       email,
  //       password,
  //     }
  //   });
  //   if (response.status >= 300) {
  //     throw new Error();
  //   }
  //   return {
  //     bearer: response.results.access_token,
  //     expiresIn: response.results.expires_in
  //     // bearer: response.access_token,
  //     // expiresIn: response.expires_in
  //   };
  // }

  public setAuthorization(bearer: string): void {
    this.defaultHeaders.set('Authorization', 'Bearer ' + bearer);
  }

  public async logout(): Promise<undefined> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    await request.post<ResponseSDKBase<null>>(this.baseUrl + 'auth/logout');
    // if (response.status >= 300) {
    //   throw new Error();
    // }
    return undefined;
  }
  /* ./AUTH */

  public async getUsers(): Promise<ResponseSDKBase<ResponseResults.User[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.User[]>>(
      this.baseUrl + 'users'
    );
    return response;
  }

  public async getMe(): Promise<ResponseSDKBase<ResponseResults.User>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.User>>(
      this.baseUrl + 'users/me'
    );
    return response;
  }

  public async getMenus(): Promise<ResponseSDKBase<ResponseResults.Menu[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Menu[]>>(
      this.baseUrl + 'menus'
    );
    return response;
  }

  public async getMenu(id: number): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Menu>>(
      this.baseUrl + 'menus/' + id,
      {
        data: {
          includes: ['sections']
        }
      }
    );
    return response;
  }

  public async createMenus(data: RequestFilters.MenuCreate): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Menu>>(
      this.baseUrl + 'menus',
      {
        data: {
          menu: data
        }
      }
    );
    return response;
  }

  public async updateMenus({id, image, ...data}: RequestFilters.MenuUpdate): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);

    const response = await request.post<ResponseSDKBase<ResponseResults.Menu>>(
      this.baseUrl + 'menus/' + id,
      this.buildMultipartRequestOptions({
        file: ['image', image],
        data: [['menu', data]/*, ['includes', ['sections']]*/],
        method: 'put',
      }),
    );
    return response;
  }

  // public async getMenuSections(menu_id: number): Promise<ResponseSDKBase<ResponseResults.Menu>> {
  //   const request: R = new this.builderRequest(this.defaultHeaders);
  //   const response = await request.get<ResponseSDKBase<ResponseResults.Menu>>(
  //     this.baseUrl + 'menusections',
  //     {
  //       data: {
  //         menusection: {

  //         }
  //       }
  //     }
  //   );
  //   return response;
  // }

  public async createMenuSection(data: RequestFilters.MenuSectionCreate): Promise<ResponseSDKBase<ResponseResults.Menu>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Menu>>(
      this.baseUrl + 'menusections',
      {
        data: {
          menusection: data
        }
      }
    );
    return response;
  }

  public async updateMenuSection({id, image, ...data}: RequestFilters.MenuSectionUpdate): Promise<ResponseSDKBase<ResponseResults.MenuSection>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.MenuSection>>(
      this.baseUrl + 'menusections/' + id,
      this.buildMultipartRequestOptions({
        file: ['image', image],
        data: [['menusection', data]/*, ['includes', ['dishes']]*/],
        method: 'put',
      }),
      // {
      //   data: {
      //     menusection: data,
      //     includes: ['dishes'],
      //   }
      // }
    );
    return response;
  }

  public async getMenuSection(id: number): Promise<ResponseSDKBase<ResponseResults.MenuSection>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.MenuSection>>(
      this.baseUrl + 'menusections/' + id,
      {
        data: {
          includes: ['dishes']
        }
      }
    );
    return response;
  }

  public async getMenuSectionDish(id: number): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Dish>>(
      this.baseUrl + 'dishes/' + id,
      {
        data: {
          includes: ['ingredients', 'tags']
        }
      }
    );
    return response;
  }

  public async createDish(data: RequestFilters.DishCreate): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Dish>>(
      this.baseUrl + 'dishes',
      {
        data: {
          dish: data
        }
      }
    );
    return response;
  }

  public async updateDish({id, image, ...data}: RequestFilters.DishUpdate): Promise<ResponseSDKBase<ResponseResults.Dish>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Dish>>(
      this.baseUrl + 'dishes/' + id,
      this.buildMultipartRequestOptions({
        file: ['image', image],
        data: [['dish', data]/*, ['includes', ['ingredients', 'tags']]*/],
        method: 'put',
      }),
      // {
      //   data: {
      //     dish: data,
      //     // includes: ['dishes'],
      //   }
      // }
    );
    return response;
  }

  public async createIngredient(data: RequestFilters.IngredientCreate): Promise<ResponseSDKBase<ResponseResults.Ingredient>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Ingredient>>(
      this.baseUrl + 'ingredients',
      {
        data: {
          ingredient: data,
        }
      }
    );
    return response;
  }

  public async getIngredients(): Promise<ResponseSDKBase<ResponseResults.Ingredient[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Ingredient[]>>(
      this.baseUrl + 'ingredients',
      {
        // data: {
        //   includes: ['allergens']
        // }
      }
    );
    return response;
  }

  public async updateDishAddIngredient({dish_id, ingredient_id}: {
    dish_id: string,
    ingredient_id: string,
  }): Promise<ResponseSDKBase<ResponseResults.Ingredient[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Ingredient[]>>(
      this.baseUrl + 'dishes/' + dish_id,
      {
        data: {
          dish: {
            ingredient_id,
          },
          // includes: ['ingredients']
        }
      }
    );
    return response;
  }

  public async createTag(data: RequestFilters.TagCreate): Promise<ResponseSDKBase<ResponseResults.Tag>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<ResponseResults.Tag>>(
      this.baseUrl + 'tags',
      {
        data: {
          tag: data,
        }
      }
    );
    return response;
  }

  public async getTags(): Promise<ResponseSDKBase<ResponseResults.Tag[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<ResponseResults.Tag[]>>(
      this.baseUrl + 'tags',
      {
        // data: {
        //   includes: ['allergens']
        // }
      }
    );
    return response;
  }

  public async updateDishAddTag({dish_id, tag_id}: {
    dish_id: string,
    tag_id: string,
  }): Promise<ResponseSDKBase<ResponseResults.Tag[]>> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.put<ResponseSDKBase<ResponseResults.Tag[]>>(
      this.baseUrl + 'dishes/' + dish_id,
      {
        data: {
          dish: {
            tag_id,
          },
          // includes: ['tags']
        }
      }
    );
    return response;
  }
}
