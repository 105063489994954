import { Utils } from '../../../../lib/utils/core';

enum values {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
};

export const AUTH = Utils.keyValueFromEnum<typeof values>(values);
