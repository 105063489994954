import { RequestHandler } from '../interfaces/RequestHandler';
import { Headers } from '../interfaces/Headers';

export interface ResponseSDKBase<T> {
  error: boolean;
  message: string;
  results: T;
  status: number;
  metaQuery: { [key: string]: any };
}

export abstract class BaseSDK<R extends RequestHandler> {
  protected abstract baseUrl: string;
  protected defaultHeaders: Headers;
  protected defaultLimit = 20;

  constructor(
    protected builderRequest: new (defaultHeaders?: Headers) => R,
  ) {
    this.defaultHeaders = new Headers([
      // ['User-Agent', 'Request-Promise']
    ]);
  }

  public setLimit(limit: number): void {
    if (limit <= 200 && limit >= 1) {
      this.defaultLimit = limit;
    }
  }

  public setLocale(locale: string): void {
    this.defaultHeaders.set('x-locale', locale);
  }

  public async get<Filters, Results>(
    url: string,
    filters?: Filters
  ): Promise<Results> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.get<ResponseSDKBase<Results>>(this.baseUrl + url, filters);
    if (response.status >= 300) {
      throw new Error();
    }
    // TODO: handle 401 error
    return response.results;
  }

  public async post<Filters, Results>(
    url: string,
    filters?: Filters
  ): Promise<Results> {
    const request: R = new this.builderRequest(this.defaultHeaders);
    const response = await request.post<ResponseSDKBase<Results>>(this.baseUrl + url, filters);
    if (response.status >= 300) {
      throw new Error();
    }
    // TODO: handle 401 error
    return response.results;
  }
}
