import { ActionInterface } from './../../../lib/redux/models/action';
import { takeLatest, call, put } from '@redux-saga/core/effects';

import { Utils } from '../../../lib/utils/core';
import { ResponseBase } from '../../../lib/sdk/interfaces/RequestHandler';

import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';
import sdk from '../../../app-lib/sdk/sdk.service';

import { MENU } from './reducer/types';
// import { getAuth } from '../auth/auth.saga';
// import { AuthState } from '../auth/reducer/reducer';
import {
  menuGetAllFailure,
  menuGetAllSuccess,
  menuGetFailure,
  menuGetRequest,
  menuGetSuccess,
  menuCreateSuccess,
  menuCreateFailure,
  menuCreateRequest,
  menuUpdateSuccess,
  menuUpdateFailure
} from './reducer/actions';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
// import { logoutRequest } from '../auth/reducer/actions';

export function* getAllMenu(): Generator {
  // const auth = (yield select(getAuth)) as AuthState;
  // if (!auth.token) {
  //   return;
  // }
  try {
    const response = (yield call(sdk.getMenus.bind(sdk))) as ResponseBase<ResponseResults.Menu[]>;
    yield put(menuGetAllSuccess(response.results));
  } catch (e) {
    // console.log(e);
    yield put(menuGetAllFailure(Utils.formatErrors(e)));
    // yield put(logoutRequest());
  }
}

export function* getMenu({data}: any): Generator {
  // const auth = (yield select(getAuth)) as AuthState;
  // if (!auth.token) {
  //   return;
  // }
  try {
    const response = (yield call(sdk.getMenu.bind(sdk, data.id))) as ResponseBase<ResponseResults.Menu>;
    yield put(menuGetSuccess(response.results));
  } catch (e) {
    // console.log(e);
    yield put(menuGetFailure(Utils.formatErrors(e)));
    // yield put(logoutRequest());
  }
}

export function* createMenu({ data }: ActionInterface<RequestFilters.MenuCreate>): Generator {
  // const auth = (yield select(getAuth)) as AuthState;
  // if (!auth.token) {
  //   return;
  // }
  try {
    const response = (yield call(sdk.createMenus.bind(sdk, data))) as ResponseBase<ResponseResults.Menu>;

    // yield put(menuGetRequest());

    yield put(menuCreateSuccess(response.results));
  } catch (e) {
    // console.log(e);
    yield put(menuCreateFailure(Utils.formatErrors(e)));
    // yield put(logoutRequest());
  }
}

export function* updateMenu({ data }: ActionInterface<RequestFilters.MenuUpdate>): Generator {
  // const auth = (yield select(getAuth)) as AuthState;
  // if (!auth.token) {
  //   return;
  // }
  try {
    const response = (yield call(sdk.updateMenus.bind(sdk, data))) as ResponseBase<ResponseResults.Menu>;

    // yield put(menuGetRequest());

    yield put(menuUpdateSuccess(response.results));
  } catch (e) {
    // console.log(e);
    yield put(menuUpdateFailure(Utils.formatErrors(e)));
    // yield put(logoutRequest());
  }
}

export default [
  takeLatest(MENU.MENU_GETALL_REQUEST, getAllMenu),
  takeLatest(MENU.MENU_GET_REQUEST, getMenu),
  takeLatest(MENU.MENU_CREATE_REQUEST, createMenu),
  takeLatest(MENU.MENU_UPDATE_REQUEST, updateMenu),
];
