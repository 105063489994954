import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../components/atoms/loader';
import { useHistory, useParams } from 'react-router-dom';
import sdk from '../../../app-lib/sdk/sdk.service';
import { ResponseResults } from '../../../app-lib/sdk/interfaces/ResponseResults';

import List, { ListArgs } from '../components/organisms/list.component';
import { RequestFilters } from '../../../app-lib/sdk/interfaces/RequestFilters';
import { UpdateForm } from '../components/organisms/updateForm.component';

export default function MenuSection(props: any): JSX.Element {
  const history = useHistory();

  const params = useParams<{menuId: string, sectionId: string}>()

  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState<ResponseResults.MenuSection>();
  const [errors, setErrors] = useState<string[]>([]);

  const loadData = useCallback(() => {
    sdk.getMenuSection(Number(params.sectionId))
      .then(res => setSection(res.results))
      .catch(res => setErrors(res))
      .finally(() => setLoading(false))
  }, []);

  useEffect(() => {
    loadData()
  }, []);

  const ListDish = List as React.FC<ListArgs<ResponseResults.Dish>>;

  return (
    <div className="container">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errors.map((error) => (
            <p>{error}</p>
          ))}
        </div>
      )}

      <h1 className="text-center">Sezione del menu</h1>

      {loading
        ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="col-6 offset-3">
            <UpdateForm
              model={section}
              {...loading}
              fields={[
                // {
                //   name: 'is_active',
                //   type: 'switch',
                //   placeholder: 'Is active',
                // },
                {
                  name: 'name',
                  type: 'text',
                  placeholder: 'Enter menu section name...',
                },
                {
                  name: 'description',
                  type: 'text',
                  placeholder: 'Enter menu section description...',
                },
                {
                  name: 'image',
                  type: 'image',
                  placeholder: 'Add image...',
                },
              ]}
              onSubmit={(values: RequestFilters.MenuSectionUpdate) => {
                console.log('submit', values)
                setLoading(true);
                sdk.updateMenuSection(values)
                  .then(loadData)
                  .catch(e => {setErrors(e); setLoading(false)})
              }}
            />

            <ListDish
              model={section?.dishes || []}
              addPlaceholder="Add dish"
              title="Dishes"
              generateListLink={(model: any) => {
                return `/menus/${params.menuId}/section/${params.sectionId}/dish/${model.id}`;
              }}
              onCreateNew={(value: string) => {
                sdk.createDish({
                  name: value,
                  // price: 0,
                  // @ts-ignore
                  menu_section_id: section?.id
                })
                  .then(((dish: any) => history.push('/menus/' + params.menuId + '/section/' + section?.id + '/dish/' + dish.results.id)))
                  .catch((e: any) => setErrors(e))
              }}
            />
          </div>
        )
      }
    </div>
  );
}
