import { all } from 'redux-saga/effects';

import { generalSaga } from './general.saga';

// TODO: try with dynamic import https://survivejs.com/webpack/techniques/dynamic-loading/
const saga_files = require.context('../resources', true, /\.saga\.ts$/i);
const sagas: any[] = saga_files.keys().map((path: any) => {
  return saga_files(path).default;
});

const DEFAULT_SAGAS: any[] = [
  ...generalSaga,
];

export default function* (): Generator {
  yield all(
    sagas.reduce(
      (aggr: any[], saga: any) => {
        aggr.push(...saga);
        return aggr;
      },
      DEFAULT_SAGAS
    )
  );
}
