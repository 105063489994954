import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

const Item = ({model, link}: any) => {
  return (
    <Link to={link} style={{display: 'block'}}>
      <span>{model.name}</span>
      <span style={{float: "right"}}>&gt;</span>
    </Link>
  )
}

export interface ListArgs<T = any> {
  model: T[];
  onCreateNew: (value: string) => void;
  generateListLink: (item: T) => string;
  addPlaceholder: string;
  title: string;
}

const List: React.FC<ListArgs> = ({model, onCreateNew, generateListLink, addPlaceholder, title}) => {
  const [value, setValue] = useState<string>('');

  const createNew = useCallback(
    (e) => e.key === 'Enter' && (
      onCreateNew(value)
    ),
    [value]
  );

  return (
    <>
      {title && <p className="paragraph mt-3 mb-0 text-dark text-uppercase font-weight-bold">{title}</p>}

      {model.map((item: any, index: number) => (
        <Item
          key={index}
          model={item}
          link={generateListLink(item)}
        />
      ))}

      <input
        type="text"
        className="form-control mt-3"
        value={value}
        placeholder={addPlaceholder}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={createNew}
      />
    </>
  );
}

export default List;
