import { ActionInterface } from '../../../../lib/redux/models/action';
import { ResponseResults } from '../../../../app-lib/sdk/interfaces/ResponseResults';
import { MENU } from './types';

interface ResourceState<T> {
  model: false | T;
  loading: boolean;
  errors: false | string[];

  // loading: boolean;
  // success?: boolean;
  // edited?: boolean;
  // deleted?: boolean;
}

interface MenuState {
  list: ResourceState<ResponseResults.Menu[]>;
  detail: ResourceState<ResponseResults.Menu>;
  // create: ResourceState<ResponseResults.Menu>;
}

const initialState: MenuState = {
  list: {
    model: false,
    loading: false,
    errors: false
  },
  detail: {
    model: false,
    loading: false,
    errors: false
  },
  // create: {
  //   model: false,
  //   loading: false,
  //   errors: false
  // },
  // menu: false,
  // loading: false,
  // errors: false,
};

export default function userReducer(
  state: MenuState = initialState,
  action: ActionInterface<ResponseResults.Menu & ResponseResults.Menu[] & string[]>,
): MenuState {
  switch (action.type) {
    case MENU.MENU_GETALL_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          model: false,
          errors: false,
        }
      };
    case MENU.MENU_GETALL_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          model: action.data,
        }
      };
    case MENU.MENU_GETALL_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          errors: action.data,
        }
      };

    case MENU.MENU_GET_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          model: false,
          errors: false,
        }
      };
    case MENU.MENU_GET_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          model: action.data,
        }
      };
    case MENU.MENU_GET_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          errors: action.data,
        }
      };

    case MENU.MENU_CREATE_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          model: false,
          errors: false,
        }
      };
    case MENU.MENU_CREATE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          model: action.data,
        }
      };
    case MENU.MENU_CREATE_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          errors: action.data,
        }
      };

    case MENU.MENU_UPDATE_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          // model: false,
          errors: false,
        }
      };
    case MENU.MENU_UPDATE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          model: action.data,
        }
      };
    case MENU.MENU_UPDATE_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          errors: action.data,
        }
      };

    case MENU.MENU_ADDSECTION_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          // model: false,
          errors: false,
        }
      };
    case MENU.MENU_ADDSECTION_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          model: {
            ...state.detail.model as ResponseResults.Menu,
            sections: (() => {
              if (!state.detail.model) return [];
              if (state.detail.model.sections) {
                return [
                  ...state.detail.model.sections,
                  action.data as any
                ]
              } else {
                return [
                  action.data
                ];
              }
            })()
          }
          // model: action.data,
        }
      };
    case MENU.MENU_ADDSECTION_FAILURE:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          errors: action.data,
        }
      };

    default:
      return state;
  }
}
