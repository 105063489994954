import React from "react";

export default (props: any) => (
  <></>
  // <footer className="sticky-footer bg-white">
  //   <div className="container my-auto">
  //     <div className="copyright text-center my-auto">
  //       <span>Copyright &copy; Your Website 2020</span>
  //     </div>
  //   </div>
  // </footer>
);
