import { ActionInterface } from '../../../../lib/redux/models/action';
import { GenericObject } from '../../../../lib/utils/models';

import { USER } from './types';

export function userMeRequest(): ActionInterface<GenericObject<any>> {
  return {
    type: USER.USER_ME_REQUEST,
    data: {},
  };
}

export function userMeSuccess(
//   data: UserInterface,
// ): ActionInterface<UserInterface> {
  data: any,
): ActionInterface<any> {
  return {
    type: USER.USER_ME_SUCCESS,
    data,
  };
}

export function userMeFailure(
  errors: GenericObject<any>,
): ActionInterface<GenericObject<any>> {
  return {
    type: USER.USER_ME_FAILURE,
    data: errors,
  };
}
