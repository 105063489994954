import React from "react";

import "startbootstrap-sb-admin-2/vendor/bootstrap/js/bootstrap.bundle.min.js";
import '../../scss/index.scss';


import HeaderComponent from "../organisms/header.component";
// import Footer from "../molecules/footer.component";
import SidebarComponent from "../organisms/sidebar.component";
import FooterComponent from "../organisms/footer.component";

// Core plugin JavaScript
// import "startbootstrap-sb-admin-2/vendor/jquery-easing/jquery.easing.min.js";

// Custom scripts for all pages
// import "startbootstrap-sb-admin-2/js/sb-admin-2.min.js";

// Page level plugins
// import "startbootstrap-sb-admin-2/vendor/chart.js/Chart.min.js";

// Page level custom scripts
// import "startbootstrap-sb-admin-2/js/demo/chart-area-demo.js";
// import "startbootstrap-sb-admin-2/js/demo/chart-pie-demo.js";

export default ({children, ...props}: any) => (
  <>
    <div id="wrapper">
      <SidebarComponent />

      <div id="content-wrapper" className="d-flex flex-column">
        <HeaderComponent />
        {children}
        <FooterComponent />
      </div>
    </div>

    {/*  Scroll to Top Button--> */}
    <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up"></i>
    </a>
  </>
);
