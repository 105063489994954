import { GENERAL } from './types';
import { ActionInterface } from '../../../../lib/redux/models/action';
import { GenericObject } from '../../../../lib/utils/models';

export function startupSuccess(): ActionInterface<GenericObject<any>> {
  return {
    type: GENERAL.STARTUP_SUCCESS,
    data: {},
  };
}

// export function toggleCollapseHeader(show: boolean): ActionInterface<boolean> {
//   return {
//     type: GENERAL.TOGGLE_COLLAPSE_HEADER,
//     data: show,
//   };
// }
