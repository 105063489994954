import { Utils } from '../../../../lib/utils/core';

enum values {
  MENU_GET_REQUEST,
  MENU_GET_SUCCESS,
  MENU_GET_FAILURE,

  MENU_GETALL_REQUEST,
  MENU_GETALL_SUCCESS,
  MENU_GETALL_FAILURE,

  MENU_CREATE_REQUEST,
  MENU_CREATE_SUCCESS,
  MENU_CREATE_FAILURE,

  MENU_UPDATE_REQUEST,
  MENU_UPDATE_SUCCESS,
  MENU_UPDATE_FAILURE,

  MENU_ADDSECTION_REQUEST,
  MENU_ADDSECTION_SUCCESS,
  MENU_ADDSECTION_FAILURE,
};

export const MENU = Utils.keyValueFromEnum<typeof values>(values);
