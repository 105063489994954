import { ActionInterface } from './../../../../lib/redux/models/action';
import { GenericObject } from './../../../../lib/utils/models';
import { GENERAL } from './types';

const initialState = {
  // showCollapseHeader: false,
};

export default function generalReducer(
  state: GenericObject<any> = initialState,
  action: ActionInterface<GenericObject<any> | boolean>,
): GenericObject<any> {
  switch (action.type) {
    case GENERAL.STARTUP_SUCCESS:
      return state;
    // case GENERAL.TOGGLE_COLLAPSE_HEADER:
    //   return {
    //     ...state,
    //     showCollapseHeader: action.data,
    //   };
    default:
      return state;
  }
}
