import { StoreType } from './configureStore';
import { ActionInterface } from './../models/action';

export function observeStore<State, Action extends ActionInterface<any>, T = any> (
  store: StoreType<Action>,
  select: (state: State) => T,
  onChange: (current: T) => void
) {
  let currentState: T;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}
